import React, {useState,useEffect} from 'react'
import { graphql } from 'gatsby'
import { PerfectPair, Layout, BigMenu, Navbar, Seo } from '../../components'

const PerfectPairPage = ({data}) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    return (
        <Seo title={"The Perfect Pair Cooking Series"}>
            <Layout>
                <Navbar/>
                <BigMenu/>
                <PerfectPair data={data.datoCmsPerfectPair}/>
            </Layout>
        </Seo>
    )
}

export default PerfectPairPage

export const query = (graphql`
query {
    datoCmsPerfectPair {
        headerImage{
            sizes(maxWidth: 500, imgixParams: { fm: "png" }){
                ...GatsbyDatoCmsSizes
            } 
        }
        title
        videos {
            id
            description
            title
            videoId
          }
    }
}
`)